import React from 'react';

// Import the ParticlesConfig component
import ParticlesConfig from "./components/ParticlesConfig/ParticlesConfig";

/**
 * App
 * 
 * @desc The main component of the application, renders the ParticlesConfig component.
 * @returns {JSX.Element} JSX element representing the application.
 */
const App = () => {
  // Render the ParticlesConfig component
  return <ParticlesConfig />;
};

// Export the App component as the default export
export default App;