import React from 'react';

// Import the ReactDOM library's client methods
import ReactDOM from 'react-dom/client';

// Import the main App component from the local file
import App from './App';

// Create a root using the ReactDOM.createRoot method and select the root element in the HTML document
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component inside a <React.StrictMode> element inside the root
root.render(
  <React.StrictMode>
    { /* Render the App component inside the <React.StrictMode> element */ }
    <App />
  </React.StrictMode>
);