import { useCallback } from "react"; // Importing useCallback hook from React
import Particles from "react-tsparticles"; // Importing Particle component from react-tsparticles package
import { loadFull } from "tsparticles"; // Importing the loadFull function from tsparticles library

/**
 * This component configures and displays the particle animation.
 * @returns {JSX.Element} JSX element containing the particle animation.
 */
const ParticlesConfig = () => {
  /**
   * Callback function to handle the loading of the particle animation.
   * @param {object} container - Object representing the container of the particle animation.
   * @returns {Promise} Promise resolved after the processing.
   */
  const particlesLoaded = useCallback(async (container) => {
    // You can do something here after the particles are loaded
    // await console.log(container);
  }, []);

  /**
   * Callback function to initialize the particle animation.
   * @param {object} engine - Object representing the engine of the particle animation.
   * @returns {Promise} Promise resolved after the processing.
   */
  const particlesInit = useCallback(async (engine) => {
    // Load the tsparticles library with the specified engine
    await loadFull(engine);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            // Specify the background color of the particle animation
            value: "#111",
          },
        },
        fullScreen: true,
        smooth: true,
        fpsLimit: 120,
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            // Specify the color of the particles
            value: ["#333"],
          },
          links: {
            color: "#999",
            distance: 150,
            enable: true,
            opacity: 0.4,
            width: 1,
          },
          collisions: {
            // Enable particle collisions
            enable: true,
          },
          move: {
            // Set the movement of the particles
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            speed: 1,
            straight: false,
          },
          number: {
            // Set the number and density of the particles
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            // Set the opacity of the particles
            value: { min: 0.4, max: 0.8 },
          },
          shape: {
            // Set the shape of the particles
            type: ["edge"],
          },
          size: {
            // Set the size range of the particles
            value: { min: 2, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default ParticlesConfig;